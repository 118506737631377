import { Button as AntButton } from 'antd';
import styled from 'styled-components';

function Button({ children, color, ...props }) {
    return (
        <ButtonStyled color={color} {...props}>
            {children}
        </ButtonStyled>
    );
}

const ButtonStyled = styled(AntButton)`
    &.ant-btn-primary {
        background-color: ${({ color }) => color || '#008AC8'};
        border-color: ${({ color }) => color || '#008AC8'};

        :hover,
        :focus,
        :active {
            opacity: 0.8;
        }
    }
`;

export default Button;
