import { getUrlParameter } from 'lib';
import { useCallback, useEffect, useState } from 'react';
import sessionStorage from 'store/storages/sessionStorage';
import { SESSION_KEY } from 'const';
import api, { endpoints } from 'api';
import { useHistory } from 'react-router';
import paths from 'routes/paths';
import Spinner from 'components/Loader';

function PayPalRedirect(props) {
    const [loading, showLoader] = useState(true);
    const [paymentFailed, setPaymentFailed] = useState(false);

    const history = useHistory();

    const showFailedMessage = () => {
        showLoader(false);
        setPaymentFailed(true);
    };

    const completePayPalPayment = useCallback(
        async (paymentData) => {
            try {
                const req = await api({
                    method: 'post',
                    url: endpoints.PAYPAL_SUBMIT,
                    data: paymentData,
                });

                const res = await req.data;

                if (res) {
                    history.push(paths.SUCCESS, { message: res.message });
                }
            } catch (error) {
                showFailedMessage();
            }
        },
        [history]
    );

    useEffect(() => {
        const payId = sessionStorage.read(SESSION_KEY.pay_id);
        const paymentId = getUrlParameter('paymentId');
        const payerId = getUrlParameter('PayerID');

        if (payId && paymentId && payerId) {
            completePayPalPayment({
                fp_uid: payId,
                payment_id: paymentId,
                payer_id: payerId,
            });
        } else {
            showFailedMessage();
        }
    }, [completePayPalPayment]);

    if (paymentFailed) {
        return (
            <h3 className="text-warning">
                Sorry we can not process your payment. Please try again!
            </h3>
        );
    }

    return (
        <Spinner
            spinning={loading}
            tip="Please wait! We are processing your payment!"
            style={{ width: '100%' }}
        />
    );
}

export default PayPalRedirect;
