import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import Body from './Body';
import Header from './Header';

function Layout({ children }) {
    return (
        <LayoutStyled className="mx-auto">
            <Header />

            <Body>{children}</Body>
        </LayoutStyled>
    );
}

const LayoutStyled = styled(AntLayout)`
    width: 100%;
    max-width: 600px;
`;

export default Layout;
