import { Result } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import sessionStorage from 'store/storages/sessionStorage';

function PaymentSuccess(props) {
    const { state } = useLocation();

    useEffect(() => {
        sessionStorage.clearAll();
    }, []);

    const successMessage = useMemo(() => {
        if (state && state['message'] && typeof state['message'] === 'string') {
            return state['message'];
        }

        return 'Your Payment is Verified!';
    }, [state]);

    return (
        <Result status="success" title={successMessage} subTitle="Thank you!" />
    );
}

export default PaymentSuccess;
