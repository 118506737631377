import Divider from 'components/Divider';
import { PageHeader } from 'components/Headers';
import Spinner from 'components/Loader';
import {Typography} from "antd";

const { Text } = Typography;
function ManualACHSuccess() {

    return (
        // set spinning to loading
        <Spinner spinning={false}>
            <PageHeader heading="Thank you!" />
            <Divider />
            <Text type="success">ACH information added successfully. We will process the payment soon.</Text>

        </Spinner>
    );
}

export default ManualACHSuccess;
