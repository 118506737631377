const PAYMENT_CHECK = '/fast-pay/check/';
const SEND_PAYMENT = '/fast-pay/submit/';
const PLAID_TOKEN = '/ach/init/';
const ACH_PAYMENT = '/ach/submit/';
const PAYPAL_LINK = '/paypal/init/';
const PAYPAL_SUBMIT = '/paypal/submit/';
const AMAZON_PAY_SUBMIT = '/amazon-pay/submit/';
const POST_MANUAL_ACH = '/ach/manual/submit/';

export default {
    PAYMENT_CHECK,
    SEND_PAYMENT,
    PLAID_TOKEN,
    ACH_PAYMENT,
    PAYPAL_LINK,
    PAYPAL_SUBMIT,
    AMAZON_PAY_SUBMIT,
    POST_MANUAL_ACH,
};
