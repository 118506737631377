import Divider from 'components/Divider';
import {PageHeader} from 'components/Headers';
// import Space from 'components/Space';
import {SESSION_KEY} from 'const';
import {toUsCurrency} from 'lib';
import {useEffect, useState} from 'react';
import sessionStorage from 'store/storages/sessionStorage';
// import PlaidPay from './PlaidPay';
import api, {endpoints} from 'api';
import Spinner from 'components/Loader';
import {Button, Form, Input, notification} from 'antd';
import paths from "../../routes/paths";
import {useHistory} from "react-router";

function BankDraft(props) {
    // const [plaidToken, setPlaidToken] = useState('');
    const [loading, toggleLoading] = useState(false);

    const caseId = sessionStorage.read(SESSION_KEY.case_id);
    const amount = sessionStorage.read(SESSION_KEY.amount);

    const history = useHistory();

    useEffect(() => {
        /*
        const method = sessionStorage.read(SESSION_KEY.method);
        const payId = sessionStorage.read(SESSION_KEY.pay_id);
        const fetchPlaidToken = async () => {
            try {
                const req = await api({
                    method: 'post',
                    url: endpoints.PLAID_TOKEN,
                    data: {
                        case_id: caseId,
                        fp_uid: payId,
                        method,
                        amount,
                    },
                });

                const res = await req.data;

                setPlaidToken(res.link_token);
                toggleLoading(false);
            } catch (error) {
                toggleLoading(false);

                notification.error({
                    message: `Something went wrong!`,
                    description: (
                        <p>
                            Sorry, we can&apos;t process your request. Please
                            contact with us.
                        </p>
                    ),
                });
            }
        };

        if (amount && caseId && method && payId) {
            fetchPlaidToken();
        }

         */

    }, [amount, caseId]);

    if (!caseId || !amount) {
        return (
            <h3 className="text-warning">
                Your payment information are missing. Please start again! <br/>
                Thank you!
            </h3>
        );
    }

    const onFinish = async (values) => {
        console.log('Success:', values);
        toggleLoading(true);
        try {
            const req = await api({
                method: 'post',
                url: endpoints.POST_MANUAL_ACH,
                data: {...values, case_id: caseId}
            });

            const res = await req.data;
            console.log(res)
            toggleLoading(false);
            history.push(paths.MANUAL_ACH_SUCCESS, { message: res.message });
        } catch (error) {
            toggleLoading(false);

            notification.error({
                message: `Something went wrong!`,
                description: (
                    <p>
                        Sorry, we can&apos;t process your request. Please
                        contact with us.
                    </p>
                ),
            });
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Spinner spinning={loading}>
            <PageHeader heading="Bankdraft (ACH)" showBackButton/>

            <Divider/>
            <h2>Case ID: {caseId}</h2>
            <h2>Amount: {toUsCurrency(amount)}</h2>
            <Divider/>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: false,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Bank name"
                    name="bank_name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your bank name!',
                        },
                    ]}
                >
                    <Input placeholder="Bank name" />
                </Form.Item>
                <Form.Item
                    label="Bank account number"
                    name="bank_account_number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your bank account number!',
                        },
                    ]}
                >
                    <Input placeholder="Account Number" />
                </Form.Item>
                <Form.Item
                    label="Bank routing number"
                    name="bank_routing_number"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your bank routing number!',
                        },
                    ]}
                >
                    <Input placeholder="Routing number" />
                </Form.Item>

                <Form.Item
                    label="Account holder's name"
                    name="client_name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your name here!',
                        },
                    ]}
                >
                    <Input placeholder="Your name" />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            {/* <Space direction="vertical" style={{ width: '100%' }} size={15}> */}
            {/*    {plaidToken && ( */}
            {/*        <PlaidPay token={plaidToken} showLoader={toggleLoading} /> */}
            {/*    )} */}
            {/* </Space> */}
        </Spinner>
    );
}

export default BankDraft;
