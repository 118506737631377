import PropTypes from 'prop-types';
import styled from 'styled-components';

/*
    FieldWrapper is a component which mimic Ant's Form Item component.
    It's a wrapper around Formik's field components.
*/
function FieldWrapper(props) {
    const {
        children,
        label,
        required,
        error,
        isInvalid,
        disabled,
        errClass,
    } = props;

    return (
        <div
            className={`ant-form-item ${
                isInvalid
                    ? 'ant-form-item-with-help ant-form-item-has-error'
                    : ''
            }`}
        >
            {label && (
                <div className="ant-form-item-label">
                    <Label
                        htmlFor={props.name}
                        className={required && 'ant-form-item-required'}
                        title={label}
                        disabled={disabled}
                    >
                        {label}
                    </Label>
                </div>
            )}
            <div className="ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        {children}
                    </div>
                </div>
                {isInvalid ? (
                    <Error
                        className={`ant-form-item-explain ant-form-item-explain-error ${
                            errClass || ''
                        }`}
                    >
                        <div role="alert">{error}</div>
                    </Error>
                ) : null}
            </div>
        </div>
    );
}

const Label = styled.label`
    && {
        color: ${(props) => (props.disabled ? '#CDD3D8' : '#6A7581')};
    }
`;

const Error = styled.div`
    div[role='alert'] {
        font-size: 13px;
        margin-top: 4px;
    }
`;

FieldWrapper.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    required: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    isInvalid: PropTypes.bool,
};

export default FieldWrapper;
