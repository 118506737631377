import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

function Modal({ children, ...props }) {
    return <Modal_s {...props}>{children}</Modal_s>;
}

const Modal_s = styled(AntModal)`
    .ant-modal-content {
        background-clip: border-box;

        .ant-modal-header {
            border-bottom: 0;
            padding: 24px 48px 0 24px;
            margin-bottom: 8px;

            .ant-modal-title {
                font-size: 20px;
                font-weight: 400;
            }
        }

        .ant-modal-body {
            padding: 16px;
        }
    }
`;

export default Modal;
