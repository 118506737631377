/* eslint-disable no-undef */
import { PageHeader } from 'components/Headers';
import { useScript } from 'hooks';
import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toUsCurrency } from 'lib';
import Divider from 'components/Divider';
import { SESSION_KEY } from 'const';
import sessionStorage from 'store/storages/sessionStorage';
import Button from 'components/Button';
import api, { endpoints } from 'api';
import { useHistory } from 'react-router';
import paths from 'routes/paths';
import Spinner from 'components/Loader';

const SELLER_ID = process.env.REACT_APP_AMAZON_MERCHANT_ID;
const AMAZON_SCRIPT = process.env.REACT_APP_AMAZON_SCRIPT_URL;

function AmazonCallback(props) {
    const [referenceId, setReferenceId] = useState('');
    const [loading, showLoader] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);

    const amazonLoadStatus = useScript(AMAZON_SCRIPT);
    const history = useHistory();

    const caseId = sessionStorage.read(SESSION_KEY.case_id);
    const amount = sessionStorage.read(SESSION_KEY.amount);

    useEffect(() => {
        if (amazonLoadStatus === 'ready') {
            new window.OffAmazonPayments.Widgets.AddressBook({
                sellerId: SELLER_ID,
                onOrderReferenceCreate: function (orderReference) {
                    // Here is where you can grab the Order Reference ID.
                    orderReference.getAmazonOrderReferenceId();
                    setReferenceId(orderReference.getAmazonOrderReferenceId());
                    console.log(orderReference.getAmazonOrderReferenceId());
                },
                onAddressSelect: function (billingAgreement) {
                    // Replace the following code with the action that you want
                    // to perform after the address is selected. The
                    // amazonOrderReferenceId can be used to retrieve the address
                    // details by calling the GetOrderReferenceDetails operation.
                    // If rendering the AddressBook and Wallet widgets
                    // on the same page, you do not have to provide any additional
                    // logic to load the Wallet widget after the AddressBook widget.
                    // The Wallet widget will re-render itself on all subsequent
                    // onAddressSelect events without any action from you.
                    // We don't recommend that you explicitly refresh it.
                    // console.log(billingAgreement);
                },
                design: {
                    designMode: 'responsive',
                    innerHeight: 500,
                },
                onReady: function (orderReference) {
                    // Enter code here that you want to be executed
                    // when the address widget has been rendered.
                },
                onError: function (error) {
                    // Your error handling code.
                    // During development you can use the following
                    // code to view error messages:
                    // console.log(error.getErrorCode() + ': ' + error.getErrorMessage());
                    // See "Handling Errors" for more information.
                    console.log(error.getErrorMessage());
                },
            }).bind('addressBookWidgetDiv');

            new window.OffAmazonPayments.Widgets.Wallet({
                sellerId: SELLER_ID,
                onPaymentSelect: function (billingAgreement) {
                    // Replace this code with the action that you want to perform
                    // after the payment method is chosen.
                    // Ideally this would enable the next action for the buyer
                    // including either a "Continue" or "Place Order" button.
                    // console.log(billingAgreement);
                },
                design: {
                    designMode: 'responsive',
                    innerHeight: 500,
                },
                onError: function (error) {
                    // Your error handling code.
                    // During development you can use the following
                    // code to view error messages:
                    // console.log(error.getErrorCode() + ': ' + error.getErrorMessage());
                    // See "Handling Errors" for more information.
                },
            }).bind('walletWidgetDiv');
        }
    }, [amazonLoadStatus]);

    const makePayment = async () => {
        try {
            const method = sessionStorage.read(SESSION_KEY.method);
            const payId = sessionStorage.read(SESSION_KEY.pay_id);

            const data = {
                case_id: caseId,
                fp_uid: payId,
                method,
                amount,
                order_reference_id: referenceId,
            };

            showLoader(true);

            const req = await api({
                method: 'post',
                url: endpoints.AMAZON_PAY_SUBMIT,
                data,
            });

            const res = await req.data;

            if (res) {
                showLoader(false);
                history.push(paths.SUCCESS, { message: res.message });
            }
        } catch (error) {
            showLoader(false);
            setPaymentFailed(true);
        }
    };

    if (paymentFailed) {
        return (
            <Fragment>
                <h3 className="text-warning">
                    Sorry we can not process your payment. Please try again!
                </h3>

                <Button onClick={() => window.location.reload()} type="primary">
                    Try again
                </Button>
            </Fragment>
        );
    }

    if (!caseId || !amount) {
        return (
            <h3 className="text-warning">
                Your payment information are missing. Please start again! <br />
                Thank you!
            </h3>
        );
    }

    return (
        <Spinner spinning={loading}>
            <Wrapper>
                <PageHeader
                    heading="Amazon Pay"
                    description="Please select your preferred Address and Payment Method"
                    showBackButton
                />
                <Divider />
                <h2>Case ID: {caseId}</h2>
                <h2>Amount: {toUsCurrency(amount)}</h2>
                <Divider />
                <div id="addressBookWidgetDiv"></div>
                <div id="walletWidgetDiv"></div>

                <PayButton
                    type="primary"
                    disabled={!referenceId}
                    onClick={makePayment}
                >
                    Make Payment
                </PayButton>
            </Wrapper>
        </Spinner>
    );
}

const Wrapper = styled.div`
    /* Please include the min-width, max-width, min-height 
/* and max-height if you plan to use a relative CSS unit 
/* measurement to make sure the widget renders in the 
/* optimal size allowed.  
*/

    #addressBookWidgetDiv {
        width: 100%;
        min-height: 280px;
        max-height: 400px;
        margin-bottom: 20px;
    }

    #walletWidgetDiv {
        width: 100%;
        min-height: 280px;
        max-height: 400px;
        margin-bottom: 20px;
    }

    /* Mobile optimized and small window */

    #addressBookWidgetDiv {
        height: 228px;
    }

    #walletWidgetDiv {
        height: 228px;
    }
`;

const PayButton = styled(Button)`
    float: right;
`;

export default AmazonCallback;
