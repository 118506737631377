import { PrevButton } from 'components/BackButton';
import { Fragment } from 'react';
import propTypes from 'prop-types';

function PageHeader({ heading, description, showBackButton }) {
    return (
        <Fragment>
            {showBackButton && <PrevButton style={{ marginBottom: '15px' }} />}
            <h1>{heading}</h1>
            <p>{description}</p>
        </Fragment>
    );
}

PageHeader.propTypes = {
    heading: propTypes.oneOfType([
        propTypes.string,
        propTypes.number,
        propTypes.node,
    ]),
    description: propTypes.oneOfType([
        propTypes.string,
        propTypes.number,
        propTypes.node,
    ]),
    showBackButton: propTypes.bool,
};

export default PageHeader;
