/* eslint-disable no-undef */
import Spinner from 'components/Loader';
import { useScript } from 'hooks';
import { useEffect } from 'react';
import paths from 'routes/paths';
import styled from 'styled-components';

const CLIENT_ID = process.env.REACT_APP_AMAZON_CLIENT_ID;
const MERCHANT_ID = process.env.REACT_APP_AMAZON_MERCHANT_ID;
const HOST_URL = process.env.REACT_APP_HOST_URL;
const AMAZON_SCRIPT = process.env.REACT_APP_AMAZON_SCRIPT_URL;

function AmazonPay(props) {
    const amazonLoadStatus = useScript(AMAZON_SCRIPT);

    useEffect(() => {
        if (amazonLoadStatus === 'ready') {
            window.onAmazonLoginReady = function () {
                window.amazon.Login.setClientId(CLIENT_ID);
            };
            window.onAmazonPaymentsReady = (function () {
                // eslint-disable-next-line new-cap
                window.OffAmazonPayments.Button(
                    'AmazonPayButton',
                    MERCHANT_ID,
                    {
                        type: 'PwA',
                        color: 'DarkGray',
                        size: 'large',
                        authorization: function () {
                            const loginOptions = {
                                scope: 'profile  postal_code payments:widget payments:shipping_address',
                                popup: true,
                            };
                            window.amazon.Login.authorize(
                                loginOptions,
                                `${HOST_URL}${paths.AMAZON_REDIRECT}`
                            );
                        },
                        onError: function (error) {
                            // your error handling code.
                            // alert("The following error occurred: "
                            //        + error.getErrorCode()
                            //        + ' - ' + error.getErrorMessage());
                        },
                    }
                );
            })();
        }
    }, [amazonLoadStatus]);

    return (
        <AmznButtonContainer id="AmazonPayButton">
            <Spinner spinning={true} style={{ marginLeft: 10, marginTop: 5 }} />
        </AmznButtonContainer>
    );
}

const AmznButtonContainer = styled.div`
    position: relative;

    img.amazonpay-button-inner-image {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
    }
`;

export default AmazonPay;
