/**
 * This function converts a number to US Currency Format
 *
 * @param {number} amount - Number to convert to US Currency
 *
 * @example
 *     toUsCurrency(1230000);
 */

export const toUsCurrency = (amount) => {
    const format = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    };

    const NumberFormat = new Intl.NumberFormat('en-US', format);
    const formattedAmount = parseFloat(amount);

    if (!isNaN(formattedAmount)) {
        return NumberFormat.format(formattedAmount);
    }
};

export const getUrlParameter = (name) => {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
