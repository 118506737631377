import Layout from 'containers/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes/Routes';
import * as Sentry from '@sentry/react';
import { ScrollToTop, ErrorFallback } from 'utilities';

function App() {
    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Layout>
                <Router>
                    <ScrollToTop />
                    <Routes />
                </Router>
            </Layout>
        </Sentry.ErrorBoundary>
    );
}
export default App;
