import styled from 'styled-components';

function Divider({ style, className }) {
    return <Line className={className} style={style} />;
}

const Line = styled.hr`
    margin: 16px 0;
    padding: 0;
    width: calc(100% + 64px);
    margin-left: -32px;
    border: 0 solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
`;

export default Divider;
