import { Direction } from 'components/Button';
import Divider from 'components/Divider';
import Space from 'components/Space';
import paths from 'routes/paths';
import { PageHeader } from 'components/Headers';
import { arrowRight, bankIcon } from 'assets/img';
// import PayPal from './PayPal';
import Spinner from 'components/Loader';
// import { useState } from 'react';
// import { AmazonPay } from '.';

function PaymentOptions() {
    // const [loading, showLoader] = useState(false);

    return (
        // set spinning to loading
        <Spinner spinning={false}>
            <PageHeader heading="Other Payment Options" showBackButton />
            <Divider />

            <Space direction="vertical" style={{ width: '100%' }} size={15}>

                {/* We are waiting for a new PayPal account. */}
                {/* <PayPal showLoader={showLoader} /> */}

                <Direction
                    link={paths.BANKDRAFT}
                    title="Bankdraft (ACH)"
                    iconRight={arrowRight}
                    iconLeft={bankIcon}
                />

                {/* <AmazonPay /> */}
            </Space>
        </Spinner>
    );
}

export default PaymentOptions;
