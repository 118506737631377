import { Checkbox as AntCheckbox } from 'antd';
import { useField } from 'formik';
import styled from 'styled-components';

function CheckboxField({ children, required, onChange, ...props }) {
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helper] = useField(props);
    // const { touched, error } = meta;

    const { setValue } = helper;

    const changeHandler = (e) => {
        const value = e.target.checked;

        setValue(value);
        if (onChange) onChange(value);
    };

    return (
        <Checkbox onChange={changeHandler} checked={field.value}>
            {children}
        </Checkbox>
    );
}

const Checkbox = styled(AntCheckbox)`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 12px;

    a {
        text-decoration: underline;
        color: #0f212e;
        font-size: 12px;
        font-weight: 500;
    }

    &:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-inner {
        border: 1px solid #0f212e;
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-color: #008ac8;
        }
    }
`;

export default CheckboxField;
