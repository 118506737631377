import styled from 'styled-components';

function Body({ children }) {
    return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.main`
    padding: 32px;
    margin-top: 58px;
`;

export default Body;
