import { Result } from 'antd';

function ErrorFallback(props) {
    return (
        <Result
            title="Sorry, something went wrong."
            subTitle="Our team has been notified."
            extra={'In the meantime please refresh the page and try again.'}
        />
    );
}

export default ErrorFallback;
