import Button from 'components/Button';
import Modal from 'components/Modal';
import { useState, useMemo } from 'react';
import { Formik } from 'formik';
import { NumberInput, Radio, RadioGroup } from 'components/FormFields';
import styled from 'styled-components';
import * as Yup from 'yup';
import { toUsCurrency } from 'lib';
import { Spin } from 'antd';

// Form initial value
const initialValue = { type: '', other_amount: '' };

const OTHER_KEY = 'other';

function AmountModal({ caseDetails, setAmount, loading }) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const { list } = caseDetails;

    const minimumAmount = useMemo(() => {
        if (Array.isArray(list) && list.length) {
            const minAmountOption = list.find(
                (opt) =>
                    opt.key === 'payment_due_amount' ||
                    opt.key === 'payment_schedule_amount'
            );

            return minAmountOption && minAmountOption['value']
                ? minAmountOption.value
                : 1;
        }

        return 1;
    }, [list]);

    const renderOptions = useMemo(() => {
        if (Array.isArray(list) && list.length) {
            return list.map(({ key, text }) =>
                key === OTHER_KEY ? (
                    <li key={key}>
                        <OtherAmount value={key}>
                            {text}
                            <NumberInput
                                name="other_amount"
                                min={minimumAmount}
                            />
                        </OtherAmount>
                    </li>
                ) : (
                    <li key={key}>
                        <Radio value={key}>{text}</Radio>
                    </li>
                )
            );
        }

        return null;
    }, [list, minimumAmount]);

    const saveSelectedAmount = (values, { setFieldError }) => {
        const { type, other_amount } = values;
        const selectedOption = list.find((opt) => opt.key === type);

        // Checking other_amount is accurate when selected Other type
        if (type === OTHER_KEY) {
            if (other_amount < minimumAmount) {
                setFieldError(
                    'other_amount',
                    `Minimum amount is ${toUsCurrency(minimumAmount)}`
                );
            } else {
                setAmount({ ...selectedOption, value: other_amount });
                setIsModalVisible(false);
            }
        } else {
            setAmount(selectedOption);
            setIsModalVisible(false);
        }
    };

    return (
        <Formik
            initialValues={initialValue}
            onSubmit={saveSelectedAmount}
            validationSchema={validationRules}
            validateOnMount
        >
            {({ handleSubmit }) => (
                <Modal
                    title="Select An Amount"
                    visible={isModalVisible}
                    destroyOnClose
                    closable={false}
                    footer={null}
                    bodyStyle={{ padding: 0 }}
                >
                    <Spin spinning={loading}>
                        <FormWrapper>
                            <RadioGroup name="type" errClass="type-err-message">
                                <OptionsList>{renderOptions}</OptionsList>
                            </RadioGroup>

                            <SubmitBtn
                                block
                                type="primary"
                                onClick={handleSubmit}
                            >
                                Set Amount
                            </SubmitBtn>
                        </FormWrapper>
                    </Spin>
                </Modal>
            )}
        </Formik>
    );
}

const validationRules = Yup.object().shape({
    type: Yup.string().required('Please select an option'),
    other_amount: Yup.number()
        .typeError('Invalid amount')
        .when('type', (type, schema) => {
            return type === OTHER_KEY
                ? schema.required('Please enter amount')
                : schema;
        }),
});

const OptionsList = styled.ul`
    list-style: none;
    margin-top: 24px;
    padding-left: 0;
    padding-bottom: 24px;
    border-top: 1px solid #e0e0e0;

    li {
        padding: 16px 16px 16px 32px;
        border-bottom: 1px solid #e0e0e0;
        display: block;

        .ant-radio-wrapper {
            display: block;
        }
    }
`;

const SubmitBtn = styled(Button)`
    height: 62px;
    font-size: 14px;
    font-weight: 500;
    text-shadow: none;
    box-shadow: none;
`;

const FormWrapper = styled.div`
    .ant-form-item {
        .ant-radio-group {
            display: block;
        }

        .ant-form-item-explain.ant-form-item-explain-error.type-err-message {
            margin-left: 32px;
            padding-bottom: 12px;
            margin-top: -15px;
        }
    }
`;

const OtherAmount = styled(Radio)`
    &&& {
        display: flex;
        align-items: center;
        margin-right: 0;
    }

    span.ant-radio + * {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .ant-form-item {
        margin-left: 16px;
        margin-bottom: 0;
    }
`;

export default AmountModal;
