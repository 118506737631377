import { logoImg } from 'assets/img';
import styled from 'styled-components';

function Header() {
    return (
        <Wrapper>
            <img className="main-logo" src={logoImg} alt="taxrise" />
        </Wrapper>
    );
}

const Wrapper = styled.header`
    height: 58px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    padding: 18px 32px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;

    img {
        height: 15px;
    }
    .main-logo {
        height: 21px;
        position: absolute;
        top: 18px;
        left: 50%;
        margin-left: -56px;
    }
`;

export default Header;
