import { useMemo } from 'react';
import { InputNumber } from 'antd';
import { useField } from 'formik';
import FieldWrapper from './FieldWrapper';
import styled from 'styled-components';

function NumberInput({ label, required, disabled, onChange, ...props }) {
    const [field, meta, helper] = useField(props);
    const { touched, error } = meta;

    const { setValue } = helper;

    const changeHandler = (value) => {
        setValue(value);
        if (onChange) onChange(value);
    };

    const isInvalid = useMemo(() => {
        return !!(touched && error);
    }, [touched, error]);

    return (
        <FieldWrapper
            isInvalid={isInvalid}
            label={label}
            required={required}
            error={error}
            disabled={disabled}
        >
            <Wrapper hasError={isInvalid}>
                <InputNumber
                    {...field}
                    {...props}
                    disabled={disabled}
                    onChange={changeHandler}
                />
            </Wrapper>
        </FieldWrapper>
    );
}

const Wrapper = styled.div`
    margin: auto;
    max-width: 754px;

    .ant-input-number {
        width: 100%;
        border: 0px;
        ${(props) =>
            props.hasError
                ? 'border: 1px solid #ff4d4f;'
                : 'border-bottom: 1px solid #008ac8;'}

        &, &:hover {
            background-color: ${(props) =>
                props.hasError ? '#fff1f1' : '#ebf2f7'};
        }

        &[disabled] {
            background-color: #cdd3d8;
            border-bottom-color: #cdd3d8;
        }
        .ant-input-number-input {
            min-height: 40px;
            padding: 0 16px;
            border: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #0f212e;
        }
    }
`;

export default NumberInput;
