import { Result } from 'antd';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import paths from 'routes/paths';

function PayPalCancel(props) {
    const history = useHistory();

    return (
        <Result
            status="warning"
            title="You have canceled your payment on PayPal. Please try again."
            extra={
                <Button
                    type="primary"
                    onClick={() => history.push(paths.PAYMENT_OPTIONS)}
                >
                    Try Again
                </Button>
            }
        />
    );
}

export default PayPalCancel;
