import { arrowLeft } from 'assets/img';
import Button from 'components/Button';
import { useHistory } from 'react-router';

function PrevButton({ style, className }) {
    const { goBack } = useHistory();

    const goPreviousPage = () => goBack();

    return (
        <Button
            size="small"
            shape="circle"
            onClick={goPreviousPage}
            style={style}
            className={className}
        >
            <img src={arrowLeft} alt="" style={{ height: '14px' }} />
        </Button>
    );
}

export default PrevButton;
